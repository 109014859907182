import "./styles.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart } from '@fortawesome/free-regular-svg-icons';

export default function Menu() {

    return (
        <>
            <div id="menu" className="section-header orange">
                <h2>Menü</h2>
                <p>Auswahl erfolgt weiter unten</p>
            </div>
                <div className="menu-container">
                    <h2 className="menu-headline">Vorspeisen</h2>
                    <p className="dish">Auf Walnussbrot gratinierter Ziegenkäse mit Salatbouquet</p>
                    <p className="dish">Blattsalat mit Ochsenfetzen und steirischem Kürbiskernöl</p>
                    <div className="intro-horizontal-separator">
                        <div className="menu-icon">
                            <FontAwesomeIcon icon={faHeart} />{' '}
                        </div>
                    </div>
                    <h2 className="menu-headline">Hauptspeisen</h2>
                    <p className="dish">Tiroler Schlutzkrapfen mit geschmorten Kirschtomaten und Bergkas</p>
                    <p className="dish">Gebratenes Saiblingsfilet auf Rote Beete Risotto</p>
                    <p className="dish">Im ganzen rosa gebratenes Roastbeef mit Kartoffelgratin und Sommergemüse</p>
                    <div className="intro-horizontal-separator">
                        <div className="menu-icon">
                            <FontAwesomeIcon icon={faHeart} />{' '}
                        </div>
                    </div>
                    <h2 className="menu-headline">Nachspeise</h2>
                    <p className="dish">Kaiserschmarren aus der gusseisernen Pfanne mit Zwetschgenröster</p>
                    <p className="dish">Verschiedene Cremes im Gläschen</p>
                </div>
        </>
    )
}
