import "./styles.css";
import useNavigation from "./useNavigation";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

export default function Navigation() {
    const { sticky, headerRef, navigationRef, showButtons, activeItem, scrollLeft, scrollRight, handleItemClick } = useNavigation();

    return (
        <div className={`page-header-navigation${sticky.isSticky ? ' sticky' : ''}`} ref={headerRef}>
            <div className="headline-wrapper">
                <a className="names link-anchor" href="#">Melli & Schorschi</a>
            </div>
            <div className="navigation-wrapper">
                {
                    showButtons ? (
                        <button id="buttonChevronLeft" className="scroll-left" onClick={scrollLeft}><FontAwesomeIcon icon={faChevronLeft} /></button>
                    ) : null
                }
                <div id="navigationContainer" className="navigation-container" ref={navigationRef}>
                    <ul className="navigation">
                        <li className={`navigation-item ${activeItem === 'invitation' ? 'active' : ''}`} onClick={() => handleItemClick('invitation')}>
                            <a href="#invitation" className="navigation-link">Einladung</a>
                        </li>
                        <li className={`navigation-item ${activeItem === 'countdown' ? 'active' : ''}`} onClick={() => handleItemClick('countdown')}>
                            <a href="#countdown" className="navigation-link">Countdown</a>
                        </li>
                        <li className={`navigation-item ${activeItem === 'brautpaar' ? 'active' : ''}`} onClick={() => handleItemClick('brautpaar')}>
                            <a href="#brautpaar" className="navigation-link">Brautpaar</a>
                        </li>
                        <li className={`navigation-item ${activeItem === 'organisatoren' ? 'active' : ''}`} onClick={() => handleItemClick('organisatoren')}>
                            <a href="#organisatoren" className="navigation-link">Organisatoren</a>
                        </li>
                        <li className={`navigation-item ${activeItem === 'tagesablauf' ? 'active' : ''}`} onClick={() => handleItemClick('tagesablauf')}>
                            <a href="#tagesablauf" className="navigation-link">Tagesablauf</a>
                        </li>
                        <li className={`navigation-item ${activeItem === 'hochzeitsAbc' ? 'active' : ''}`} onClick={() => handleItemClick('hochzeitsAbc')}>
                            <a href="#hochzeitsAbc" className="navigation-link">Hochzeits-ABC</a>
                        </li>
                        <li className={`navigation-item ${activeItem === 'location' ? 'active' : ''}`} onClick={() => handleItemClick('location')}>
                            <a href="#location" className="navigation-link">Location</a>
                        </li>
                        <li className={`navigation-item ${activeItem === 'unterkuefnte' ? 'active' : ''}`} onClick={() => handleItemClick('unterkuenfte')}>
                            <a href="#unterkuenfte" className="navigation-link">Unterkünfte</a>
                        </li>
                        <li className={`navigation-item ${activeItem === 'menu' ? 'active' : ''}`} onClick={() => handleItemClick('menu')}>
                            <a href="#menu" className="navigation-link">Menü</a>
                        </li>
                        <li className={`navigation-item ${activeItem === 'menu-selection' ? 'active' : ''}`} onClick={() => handleItemClick('menu-selection')}>
                            <a href="#menu-selection" className="navigation-link">Menü Auswahl</a>
                        </li>
                    </ul>
                </div>
                {
                    showButtons ? (
                        <button id="buttonChevronRight" className="scroll-right" onClick={scrollRight}><FontAwesomeIcon icon={faChevronRight} /></button>
                    ) : null
                }
            </div>
        </div>
    )
}
