import "./styles.css";
import  usePopup from "./usePopup"

export default function Popup() {
    const { isPopupVisible, closePopup, handleButtonClick } = usePopup();

    if (!isPopupVisible) {
        return null; // Popup wird nicht gerendert, wenn es nicht sichtbar ist
    }

    return (
        <>
            <div className="overlay" ></div>
            <div className="popup">
                <button className="popup-button popup-close" onClick={closePopup}>
                    x
                </button>
                <img className="popup-image" src={require(`../../content/images/menu_selection.png`)}/>
                <div className="popup-body">
                    <h3>Aktuelle Infos zum Hochzeitsmenü!</h3>
                    <p>Liebe Hochzeitsgäste,</p>
                    <p>wir freuen uns euch mitteilen zu können, dass ihr ab jetzt bis Ende März euer Menü für unsere Hochzeit auswählen könnt.</p>
                    <button className="popup-button" onClick={handleButtonClick}>Zum Menü/Auswahl</button>
                </div>
            </div>
        </>
    );
}
