import "./styles.css";
import useMenu from "./useMenu";
import DishSelection from './dishSelection';
import SelectionFeedback from './selectionFeedback';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart } from '@fortawesome/free-regular-svg-icons';

export default function Menu() {
    const { persons, selectedStarters, selectedMains, showEmailErrorMessage, handleFocus, handleSelectedStartersChange, handleSelectedMainsChange, handleFormSubmit, handleAdditionalPerson, data, selectedDesserts } = useMenu();
    const storedMenuSelected = JSON.parse(localStorage.getItem('menuSelected'));

    return (
        <>
            <div id="menu-selection">
                <div className="section-header orange">
                    <h2>Menü Auswahl</h2>
                    <p>Bitte bis Ende März auswählen</p>
                </div>
                <div className="selection-feedback">
                    <div id="successMessageMenu" className="success-message">
                        <p>Vielen Dank für deine Rückmeldung!</p>
                    </div>
                    {storedMenuSelected !== null ? (
                        <SelectionFeedback storedMenuSelected={storedMenuSelected} />
                    ) : null
                    }
                </div>
                {storedMenuSelected === null ? (
                    <form id="menu-form" className="menu-form" onSubmit={handleFormSubmit}>
                        <div className="form-group">
                            <input className="form-control" type="text" name="email" placeholder="Email*" onFocus={handleFocus} />
                        </div>
                        <div className="intro-horizontal-separator">
                            <div className="menu-icon">
                                <FontAwesomeIcon icon={faHeart} />{' '}
                            </div>
                        </div>
                        {persons > 1 ? (
                            <p>Auswahl Person 1</p>
                        ) : null}
                        <DishSelection index="0"></DishSelection>
                        {
                            persons > 1 ? (
                                    <>
                                        <div className="intro-horizontal-separator">
                                            <div className="menu-icon">
                                                <FontAwesomeIcon icon={faHeart} />{' '}
                                            </div>
                                        </div>
                                        <p>Auswahl Person 2</p>
                                        <DishSelection index="1"></DishSelection>
                                    </>
                                ) :
                                null
                        }
                        {showEmailErrorMessage && (
                            <div className="error-message">
                                Mit dieser Email wurde bereits Rückmeldung bzgl. dem Menü gegeben!
                            </div>
                        )
                        }
                        <button className="additional-person-button add-person" type="button" onClick={handleAdditionalPerson}>
                            + Für eine weitere Person auswählen</button>
                        <div className="form-group">
                            <button className="form-control" type="submit">
                                <span>Menü Auswahl absenden</span>
                                <div id="menu-loader" className="loader"></div>
                            </button>
                        </div>
                    </form>
                ) : null}
            </div>
        </>
    )
}
