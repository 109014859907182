import React, { useState } from 'react';

export default function useMenu() {
    const [persons, setPersons] = useState(1);
    const [selectedStarters, setSelectedStarters] = useState([]);
    const [selectedMains, setSelectedMains] = useState([]);
    const [selectedDesserts, setSelectedDesserts] = useState([]);

    const [data, setData] = useState({});
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [showEmailErrorMessage, setShowEmailErrorMessage] = useState(false);
    const gsheetGETRequest = process.env.REACT_APP_GOOGLE_SHEET_MENU_GET_REQUEST;
    const gsheetPOSTRequest = process.env.REACT_APP_GOOGLE_SHEET_MENU_POST_REQUEST;
    const handleFocus = (event) => {
        console.log(event.target)
        if (event.target.classList.contains('error')) {
            event.target.classList.remove('error')
        }
    }

    const handleSelectedStartersChange = (event, index) => {
        const newSelectedStarters = [...selectedStarters];
        newSelectedStarters[index] = event.target.value;
        setSelectedStarters(newSelectedStarters);
    };

    const handleSelectedMainsChange = (event, index) => {
        const newSelectedMains = [...selectedMains];
        newSelectedMains[index] = event.target.value;
        setSelectedMains(newSelectedMains);
    };

    const handleSelectedDessertsChange = (selectedOptions, index) => {
        const newSelectedDesserts = [...selectedDesserts];
        newSelectedDesserts[index] = event.target.value;
        setSelectedDesserts(newSelectedDesserts);
    };

    const handleAdditionalPerson = (event) => {
        if (persons === 1) {
            setPersons(2);
            event.target.innerText = "Auswahl für Person 2 entfernen"
            event.target.classList.add('remove-person')
            event.target.classList.remove('add-person')
        } else {
            setPersons(1);
            event.target.innerText = "+ Für eine weitere Person auswählen";
            event.target.classList.remove('remove-person')
            event.target.classList.add('add-person')

        }
    }

    const allInputsValid = (email, names, dishSelects) => {
        let allInputsValid = true;
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!emailPattern.test(email.value)) {
            email.classList.add("error")
            allInputsValid = false;
        }

        names.forEach(name => {
            if (name.value === '') {
                name.classList.add('error');
                allInputsValid = false;
            }
        });

        console.log(dishSelects[2].value)
        dishSelects.forEach(select => {
            if (select.value === '') {
                select.classList.add('error');
                allInputsValid = false;
            }
        })

        return allInputsValid;
    }


    const handleFormSubmit = (event) => {
        event.preventDefault();
        const formElements = event.target;
        const email = formElements.querySelector('input[name="email"]');
        const names = formElements.querySelectorAll('input[name="name"]');
        const dishSelects = formElements.querySelectorAll('select');
        const messages = formElements.querySelectorAll('textarea[name="message"]');
        console.log(dishSelects);

        if (!allInputsValid(email, names, dishSelects)) {
            return;
        } else {
            document.getElementById('menu-loader').style.display = 'inline-block'
            const formData = new FormData();

            for ( let i=0; i<persons; i++ ) {
                formData.append('persons', persons);
                formData.append('email', email.value);
                formData.append(`name_${i}`,names[i].value);
                if (i === 0) {
                    formData.append(`starter_${i}`, dishSelects[0].value);
                    formData.append(`main_${i}`, dishSelects[1].value);
                    formData.append(`dessert_${i}`, dishSelects[2].value);
                    formData.append(`message_${i}`, messages[0].value);
                } else {
                    formData.append(`starter_${i}`, dishSelects[3].value);
                    formData.append(`main_${i}`, dishSelects[4].value);
                    formData.append(`dessert_${i}`, dishSelects[5].value);
                    formData.append(`message_${i}`, messages[1].value);
                }
            }

            fetch(`${gsheetGETRequest}${email.value}`, {
                method: "GET"
            }).then((response) => {
                return response.json();
            }).then((data) => {
                if (data.message === 'Email not registered') {
                    fetch(gsheetPOSTRequest, {
                        method: "POST",
                        body: formData
                    }).then((response) => {
                        if (response.ok) {
                            setShowEmailErrorMessage(false);
                            const form = document.getElementById('menu-form');
                            var formHeight = form.offsetHeight;
                            form.style.height = formHeight + 'px';
                            setTimeout(function () {
                                form.style.height = '0px';
                                form.style.overflow = 'hidden';
                                form.style.margin = '0px auto';
                                document.getElementById('successMessageMenu').classList.add('active');
                            }.bind(form), 1);


                            setShowSuccessMessage(true);
                            const targetElement = document.querySelector('#menu');
                            const formObject = {};
                            formData.forEach((value, key) => {
                                formObject[key] = value;
                            });
                            setData(formObject);
                            localStorage.setItem('menuSelected', JSON.stringify(formObject));
                        }
                    })
                } else {
                    document.getElementById('menu-loader').style.display = 'none'
                    setShowEmailErrorMessage(true);
                }
            });
        }
    }

    return {
        persons,
        selectedStarters,
        selectedMains,
        selectedDesserts,
        setSelectedDesserts,
        data,
        showEmailErrorMessage,
        handleFocus,
        handleSelectedStartersChange,
        handleSelectedMainsChange,
        handleSelectedDessertsChange,
        handleFormSubmit,
        handleAdditionalPerson
    }
}
