import React, { useState, useEffect, useRef } from 'react';

export default function useNavigation() {
    const [sticky, setSticky] = useState({ isSticky: false, offset: 0 });
    const [activeItem, setActiveItem] = useState('invitation');
    const [showButtons, setShowButtons] = useState(false);
    const headerRef = useRef(null);
    const navigationRef = useRef(null);

    useEffect(() => {
        if (window.innerWidth <= 825) {
            setShowButtons(true);
        }
    }, []);

    window.addEventListener('resize', function () {
        if (window.innerWidth <= 825) {
            setShowButtons(true);
        } else {
            setShowButtons(false);
        }
    });

    const handleItemClick = (itemName) => {
        setActiveItem(itemName);
    };

    // handle scroll event
    const handleScroll = (elTopOffset, elHeight) => {
        if (window.scrollY > (elTopOffset + elHeight)) {
            setSticky({ isSticky: true, offset: elHeight });
        } else {
            setSticky({ isSticky: false, offset: 0 });
        }
    };
    
    // add/remove scroll event listener
    useEffect(() => {
        var header = headerRef.current.getBoundingClientRect();
        const handleScrollEvent = () => {
            handleScroll(header.top, header.height)
        }

        window.addEventListener('scroll', handleScrollEvent);

        return () => {
            window.removeEventListener('scroll', handleScrollEvent);
        };
    }, []);

    window.onload = function() {
        const container = document.getElementById('navigationContainer');
        const tolerance = 1

        container.addEventListener('scroll', function () {
            const isScrolledToLeft = container.scrollLeft === 0;
            const isScrolledToRight = container.scrollLeft + container.clientWidth + tolerance >= container.scrollWidth;
            const buttonLeft = document.getElementById('buttonChevronLeft');
            const buttonRight = document.getElementById('buttonChevronRight');


            if (isScrolledToLeft) {
                buttonLeft ?? buttonLeft?.classList.add('gray');
                buttonRight ?? buttonRight?.classList.remove('gray');
            } else {
                buttonLeft ?? buttonLeft?.classList.remove('gray');
            }

            if (isScrolledToRight) {
                buttonRight ?? buttonRight?.classList.add('gray');
                buttonLeft ?? buttonLeft?.classList.remove('gray');
            } else {
                buttonRight ?? buttonRight?.classList.remove('gray');
            }
        });
    }

    const scrollLeft = () => {
        const container = navigationRef.current;
        const scrollAmount = 150; // Betrag, um den gescrollt werden soll
        const maxScrollLeft = container.scrollWidth - container.clientWidth; // Maximales Scroll-Offset

        document.getElementById('buttonChevronRight').classList.remove('gray');
        document.getElementById('buttonChevronRight').disabled = false;
        if (container.scrollLeft - scrollAmount <= 0) {
            document.getElementById('buttonChevronLeft').classList.add('gray');
            document.getElementById('buttonChevronLeft').disabled = true;
        }

        container.scrollBy({
            left: -scrollAmount,
            behavior: 'smooth'
        });
    };

    const scrollRight = () => {
        const container = navigationRef.current;
        const scrollAmount = 150;
        const maxScrollLeft = container.scrollWidth - container.clientWidth;

        document.getElementById('buttonChevronLeft').classList.remove('gray');
        document.getElementById('buttonChevronLeft').disabled = false;
        if (container.scrollLeft + scrollAmount >= maxScrollLeft) {
            document.getElementById('buttonChevronRight').classList.add('gray');
            document.getElementById('buttonChevronRight').disabled = true;
        }

        container.scrollBy({
            left: scrollAmount,
            behavior: 'smooth'
        });
    };

    return {
        sticky,
        headerRef,
        navigationRef,
        activeItem,
        showButtons,
        scrollRight,
        scrollLeft,
        handleItemClick
    }

}
