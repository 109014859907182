import "./styles.css";
import useMenu from "./useMenu";

export default function DishSelection({index}) {
    const { handleFocus, handleSelectedStartersChange, handleSelectedMainsChange, handleSelectedDessertsChange, handleAdditionalPerson, selectedStarters, selectedMains, selectedDesserts } = useMenu();

    return (
        <div className="form-group">
            <input className="form-control" type="text" name="name" placeholder="Vor- und Nachname*"
                   onFocus={handleFocus}/>
            <select
                className="form-control menu"
                key={`starters-${index}`}
                value={selectedStarters[index] || ""}
                onChange={(e) => handleSelectedStartersChange(e, index)}
                onFocus={handleFocus}
            >
                <option value="">--Vorspeise auswählen--</option>
                <option value="bread">Auf Walnussbrot gratinierter Ziegenkäse mit Salatbouquet</option>
                <option value="salad">Blattsalat mit Ochsenfetzen und steirischem Kürbiskernöl</option>
            </select>
            <select
                className="form-control menu"
                key={`main-${index}`}
                value={ selectedMains[index] || ""}
                onChange={(e) => handleSelectedMainsChange(e, index)}
                onFocus={handleFocus}
            >
                <option value="">--Hauptspeise auswählen--</option>
                <option value="vegetarian">Tiroler Schlutzkrapfen mit geschmorten Kirschtomaten und Bergkas</option>
                <option value="fish">Gebratenes Saiblingsfilet auf Rote Beete Risotto</option>
                <option value="beef">Im ganzen rosa gebratenes Roastbeef mit Kartoffelgratin und Sommergemüse</option>
            </select>
            { index <= 0 ? (
                <p className="dessert-info">Beim Dessert müsst ihr euch noch nicht festlegen, aber gebt bitte eine Tendenz ab, damit wir ein bisschen kalkulieren können.</p>
            ) : null }
            <select
                className="form-control menu"
                key={`dessert-${index}`}
                value={ selectedDesserts[index] || ""}
                onChange={(e) => handleSelectedDessertsChange(e, index)}
                onFocus={handleFocus}
            >
                <option value="">--Dessert auswählen--</option>
                <option value="kaiserschmarren">Kaiserschmarren aus der gusseisernen Pfanne mit Zwetschgenröster</option>
                <option value="dessertGlas">Verschiedene Cremes im Gläschen</option>
            </select>

            <textarea className="form-control" name="message"
                      placeholder="Gibt es beim Essen etwas, das wir beachten sollten (z. B. Unverträglichkeiten oder Allergien)? Lass es uns wissen und hilf uns bei der Planung! (optional)"></textarea>
        </div>
    )
}
