import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart } from '@fortawesome/free-regular-svg-icons';

export default function SelectionFeedback({storedMenuSelected}) {

    console.log(storedMenuSelected?.message_0)
    const dishMapping = (dishKey) => {
        let dishName = "";

        switch (dishKey) {
            case 'bread':
                dishName = "Auf Walnussbrot gratinierter Ziegenkäse mit Salatbouquet";
                break;
            case 'salad':
                dishName = "Blattsalat mit Ochsenfetzen und steirischem Kürbiskernöl";
                break;
            case 'vegetarian':
                dishName = "Tiroler Schlutzkrapfen mit geschmorten Kirschtomaten und Bergkas";
                break;
            case 'fish':
                dishName = "Gebratenes Saiblingsfilet auf Rote Beete Risotto";
                break;
            case 'beef':
                dishName = "Im ganzen rosa gebratenes Roastbeef mit Kartoffelgratin und Sommergemüse";
                break;
            case 'kaiserschmarren':
                dishName = "Kaiserschmarren aus der gusseisernen Pfanne mit Zwetschgenröster";
                break;
            case 'dessertGlas':
                dishName = "Verschiedene Cremes im Gläschen";
                break;
            default:
                dishName = "";
                break;
        }

        return dishName
    }

    return (
        <div className="feedback-data">
            <h3>Folgendes wurde ausgewählt:</h3>
            {storedMenuSelected?.persons > 1 ? (
                <p><b>Person 1:</b> {storedMenuSelected?.name_0}</p>
            ) : null}
            <p><b>Vorspeise:</b> {dishMapping(storedMenuSelected?.starter_0)}</p>
            <p><b>Hauptspeise:</b> {dishMapping(storedMenuSelected?.main_0)}</p>
            <p><b>Dessert:</b> {dishMapping(storedMenuSelected?.dessert_0)}</p>
            {storedMenuSelected?.message_0 !== '' ? (
                <p><b>Zusatz Information:</b> {storedMenuSelected?.message_0}</p>
            ) :  null}
            {storedMenuSelected?.persons > 1 ? (
                <>
                    <div className="intro-horizontal-separator">
                        <div className="menu-icon">
                            <FontAwesomeIcon icon={faHeart} />{' '}
                        </div>
                    </div>
                    <p><b>Person 2:</b> {storedMenuSelected?.name_1}</p>
                    <p><b>Vorspeise:</b> {dishMapping(storedMenuSelected?.starter_1)}</p>
                    <p><b>Hauptspeise:</b>: {dishMapping(storedMenuSelected?.main_1)}</p>
                    <p><b>Dessert:</b> {dishMapping(storedMenuSelected?.dessert_1)}</p>
                    {storedMenuSelected?.message_1 !== '' ? (
                        <p><b>Zusatz Information:</b> {storedMenuSelected?.message_1}</p>
                    ) :  null}
                </>
            ) : null}
        </div>
    )
}
