import { useState, useEffect } from "react";

export default function usePopup() {
    const [isPopupVisible, setPopupVisible] = useState(false);
    localStorage.removeItem("popupClosed");

    useEffect(() => {
        // Überprüfen, ob das Popup schon geschlossen wurde
        const isPopupClosed = localStorage.getItem("menuPopupClosed");
        if (!isPopupClosed) {
            setPopupVisible(true);
        }
    }, []);

    const handleButtonClick = () => {
        closePopup(); // Popup schließen
        document.getElementById("menu").scrollIntoView({ behavior: "smooth" }); // Zu Unterkünften springen
    };

    function closePopup() {
        setPopupVisible(false);
        localStorage.setItem("menuPopupClosed", "true");
    }

    return {
        isPopupVisible,
        closePopup,
        handleButtonClick
    };
}
